import Head from 'next/head'
import Image from "next/legacy/image";
import { CallToAction } from '../components/CallToAction'
import { Container } from '../components/Container'
//import { Faqs } from '../components/Faqs'
import { Features } from '../components/features'
import Footer from '../components/footer'
//import { Header } from '../components/Header'
import LandingPageMenu from '../components/landingpage_menu'
import { PrimaryFeatures } from '../components/PrimaryFeatures'
import clsx from 'clsx'
import Script from 'next/script';

export default function Home() {
  return (
    <div className={clsx("px-1 bg-primary-800 text-slate-50 font-body")}>
      <Head>
        <title>Errordeck - Simple error tracking and error monitoring for developers</title>
        <meta name="description" content="An new error monitoring and error tracking service that focus on simplicity and make more pleasant fixing those errors and bugs." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@erro_app" />
        <meta name="twitter:creator" content="@hakan_nylen" />
        <meta name="twitter:title" content="Errordeck - Error monitoring made simple" />
        <meta name="twitter:description" content="An new error monitoring and error tracking service that focus on simplicity and make more pleasant fixing those errors and bugs." />
        <meta name="twitter:image" content="https://www.errordeck.com/images/social_card.png" />
      </Head>

      <Container>
        <LandingPageMenu />
      </Container>

      <div className="max-w-7xl relative mx-auto px-4 sm:px-6 lg:px-16 py-8 lg:my-32 lg:pt-10 text-slate-100 min-h-full h-full">
        <div className="flex flex-col gap-12 lg:gap-0 lg:flex-row items-center mb-32 min-h-full">
          <div className="max-w-4xl lg:mt-0 mx-auto text-center lg:text-left lg:pr-12">
            <h1 className="font-body text-7xl lg:text-8xl leading-tight bg-clip-text text-center inline-block font-extrabold tracking-tighter mb-6">Error monitoring<span className="gradient-text">Made Simple.</span></h1>
            <p className="font-body mt-4 text-lg xl:text-2xl text-center">Errordeck is an error monitoring, tracking and handling service that will help you know the most common error and help you solve them more straightforwardly.</p>
          </div>
        </div>
      </div>

      

      <PrimaryFeatures />

      <Features />

      <Container>
        <div className="mt-16">
          <div className="mx-auto max-w-2xl md:text-center mb-16">
            <h2 className="font-display text-3xl tracking-tight text-slate-200 sm:text-4xl">Other reasons to use Errordeck</h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div className="text-lg tracking-tight text-slate-200">
              <h3 className="font-display text-3xl text-slate-200 tracking-tight text-2xl mb-2">Privacy friendly</h3>
              <p className="text-lg text-slate-400 tracking-tight">Errordeck is privacy friendly, meaning we have thought of privacy throughout the product. Examples of things we do to handle privacy better than any other error handling service:</p>
              <ul className="list-disc list-inside text-slate-400 flex flex-col gap-1 mt-2">
                <li>We don't store user context from the Sentry client; we generate a hash to count unique users and throw it away.</li>
                <li>We host all data in the EU with only European providers.</li>
                <li>We don't use any third party services to collect data.</li>
                <li>We only use privacy-friendly analytics on our website - no Intercom,or Google analytics. We use Plausible Analytics, which is also EU-based.</li>
              </ul>
            </div>
            <div>
              <h3 className="font-display text-3xl text-slate-200 tracking-tight text-2xl mb-2">Simplicity</h3>
              <p className="text-lg text-slate-400 tracking-tight">We focus mainly on simplicity to make it easier to use and navigate through the product. We have also rethought how settings should look and feel. You will notice that Errordeck has inspiration from Vercel and similar.</p>
              <p className="text-lg text-slate-400 tracking-tight">We will also only focus on error handling. That means we can entirely focus on what we think you want good service for; Finding and handling Errors.</p>
            </div>
          </div>
        </div>
      </Container>

      <CallToAction />

      {/* <Faqs /> */}

      <Footer />
      <Script id="plausible" data-domain="errordeck.com" src="https://plausible.io/js/plausible.js" />
    </div>
  )
}
