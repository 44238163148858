import Button from './Button'
import { Container } from './Container'

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-transparent mt-16 py-32"
    >
{    /* <Image
        className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2347}
        height={1244}
        unoptimized
      /> */}
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-300 sm:text-4xl">
            Get started today
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-300">
            It is time to make Error handling simple again. You can try us out today.
          </p>
          <Button href="/sign_up" color="slate" className="mt-10">
            Sign up now
          </Button>
        </div>
      </Container>
    </section>
  )
}
