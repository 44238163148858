import { useEffect, useState } from 'react'
import Image, { StaticImageData } from "next/image";
import { Tab } from '@headlessui/react'
import clsx from 'clsx'

import { Container } from './Container'
import backgroundImage from '../images/background-ff.jpg'
import backgroundImageDark from '../images/background-ff-dark.jpg'
import screenshotIssues from '../images/screenshots/issues.png'
import screenshotProject from '../images/screenshots/project-dashboard.png'
import screenshotTeam from '../images/screenshots/team-dashboard.png'

interface Feature {
  title: string
  description: string
  image: StaticImageData
}

const features: Feature[] = [
  {
    title: 'Issue Overview',
    description:
      "Go through the depth of the issue's details, and find the root cause of the issue.",
    image: screenshotIssues,
  },
  {
    title: 'Project Dashboard',
    description:
      "All your issues grouped by project, and you can easily see overview of the issues, with search.",
    image: screenshotProject,
  },
  {
    title: 'Multiple Projects',
    description:
      "You can have multiple projects, and easy navigate between on top or go to team dashboard.",
    image: screenshotTeam,
  },
]

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState<'vertical' | 'horizontal'>('horizontal')

  // useEffect(() => {
  //   // watch media query for large screens in typescript
  //   const mediaQuery = window.matchMedia('(min-width: 1024px)')
  //   const handleMediaQueryChange = (e: MediaQueryListEvent) => {
  //     setTabOrientation(e.matches ? 'vertical' : 'horizontal')
  //   }
  //   mediaQuery.addEventListener('change', handleMediaQueryChange)
  //   setTabOrientation(mediaQuery.matches ? 'vertical' : 'horizontal')
  //   return () => {
  //     mediaQuery.removeEventListener('change', handleMediaQueryChange)
  //   }
  // }, [])

  return (
    <section
      id="features"
      aria-label="Features for error handling"
      className="relative overflow-hidden bg-slate-800 pt-20 pb-28 sm:py-32"
    >
      <Image
        className="absolute top-1/2 left-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
        src={backgroundImageDark}
        alt=""
        width={2245}
        height={1636}
        unoptimized 
      />
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Everything you need to handle your errors
          </h2>
        </div>
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === 'vertical'}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        'group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6',
                        selectedIndex === featureIndex
                          ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
                          : 'hover:bg-white/10 lg:hover:bg-white/5'
                      )}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            'font-display text-lg focus:outline-none',
                            selectedIndex === featureIndex
                              ? 'text-blue-600 lg:text-white'
                              : 'text-blue-100 hover:text-white lg:text-white'
                          )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          'mt-2 hidden text-sm lg:block',
                          selectedIndex === featureIndex
                            ? 'text-white'
                            : 'text-blue-100 group-hover:text-white'
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                      <Image
                        className="w-full"
                        src={feature.image}
                        alt={feature.description}
                        priority
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  )
}
