import { Container } from './Container'

interface Feature {
  title: string
  description: string
}

const features: Feature[] = [
  {
    title: 'Stack trace',
    description:
      "View the stack trace and know exactly where it failed. An excellent, nice way to know where exactly the error happened.",
  },
  {
    title: 'Graphs',
    description:
      "See easy what is going on in your application when you need to by fastly looking at the graphs. You can see spikes and an increased amount of users getting errors fast.",
  },
  {
    title: 'Email alerts',
    description:
      "As soon as we get a new error on a unique place in your application, you will get an email notification immediately, so you can quickly decide on it.",
  },
  {
    title: 'Handle issues simply',
    description:
      "Move your issue to 3 different steps depending on their status to handle the issue's lifecycle.",
  },
  {
    title: 'Search',
    description:
      "Need to find that specific error a user asked for? You can search for that error on the message, title, or URL. ",
  },
  {
    title: 'Sentry client support',
    description:
      "You can use the Sentry client to send events to us to get started quickly.",
  },
]

export function Features() {

  return (
    <section
      id="small-features"
      aria-label="Small features for Errors"
      className="relative my-24 py-24"
    >
      <Container>
        <>
          <div className="mx-auto max-w-3xl md:text-center mb-16">
            <h2 className="font-display text-3xl tracking-tight text-slate-200 sm:text-4xl">
              Other features for handling your Errors
            </h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
            {features.map((feature) => (
              <div className="border rounded-xl border-slate-600 p-6 text-center" key={feature.title}>
                <h3 className="font-display text-xl font-medium text-slate-200 tracking-tight text-2xl mb-2">{feature.title}</h3>
                <p className="text-md text-slate-300 tracking-tight">{feature.description}</p>
              </div>
            ))}
          </div>
        </>
      </Container>
    </section>
  )
}